define("discourse/plugins/History/initializers/discourse_history", ["exports", "discourse/models/user", "discourse/routes/topic"], function (_exports, _user, _topic) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "discourse_history",
    initialize: function () {
      if (_user.default.currentProp("custom_fields.protect_history") === true) {
        _topic.default.disableReplaceState = true;
      }
    }
  };
});