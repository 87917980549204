define("discourse/plugins/History/connectors/user-custom-preferences/protect-history", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="controls">
    <label class="checkbox-label">
      {{input
        type="checkbox"
        checked=controller.model.custom_fields.protect_history
      }}
      Don't update URL while scrolling through topics
    </label>
  </div>
  */
  {
    "id": "7LLZtPr9",
    "block": "[[[10,0],[14,0,\"controls\"],[12],[1,\"\\n  \"],[10,\"label\"],[14,0,\"checkbox-label\"],[12],[1,\"\\n    \"],[1,[28,[35,0],null,[[\"type\",\"checked\"],[\"checkbox\",[30,0,[\"controller\",\"model\",\"custom_fields\",\"protect_history\"]]]]]],[1,\"\\n    Don't update URL while scrolling through topics\\n  \"],[13],[1,\"\\n\"],[13],[1,[28,[32,0],[\"[[\\\"The `controller` property path was used in the `discourse/plugins/History/connectors/user-custom-preferences/protect-history.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.controller}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"input\"]]",
    "moduleName": "discourse/plugins/History/connectors/user-custom-preferences/protect-history.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});